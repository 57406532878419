<template>
	<div>
		<loadingScreen v-if="loading_screen" />
		<ContentHeader title="Farmasi" url="/farmasi" subTitle="Konfirmasi Obat" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
					<div class="col">
						<div class="card">
							<div class="card-header row">
								<h3 class="card-title">Konfirmasi Obat Pasien</h3>
							</div>
							<div class="card-body">
								<form @submit.prevent="prosesKonfirmasi">
									<div class="px-lg-2 ">
									<div class="row">
										<div class="col-lg-4">
											<div class="form-group">
											<label for="nomor_rekam_medis">No Rekam Medis</label>
											<input type="text" class="form-control" id="nomor_rekam_medis" name="nomor_rekam_medis" disabled="" v-model="rekam_medis.nomor_rekam_medis">
											</div>		
										</div>
									</div>
									<div class="row">
										<div class="col-lg-6">
											<div class="form-group">
											<label for="diagnosa">Catatan Diagnosa </label>
											<textarea class="form-control" style="min-height: 100px;" v-model="rekam_medis.diagnosa" disabled=""></textarea>
											</div>		
										</div>
									</div>
									<div class="form-group">
										<div class="row">
											<div class="col-4">
												<label for="resep_obat">Tambah Resep Obat</label>
												<multiselect style="min-width: 100px;" 
												v-model="obat_select_value"
												deselect-label="Can't remove this value"
												track-by="nama"
												label="nama"
												value="index"
												placeholder=""
												:options="data_resep_dan_obat"
												:allow-empty="false"
												@update:model-value="tambahResep"
												>
												</multiselect>
											</div>
											<div class="col-4"></div>
											<div class="col-4 d-flex align-items-center mt-4">
												<el-input type="text" placeholder="Masukkan Signa Baru" v-model="newSigna"></el-input>
												<div class="input-group-prepend">
													<div class="btn btn-secondary fas fa-plus-square fa-2x" style="cursor: pointer;" @click="addNewSigna()"></div>
												</div>
											</div>
										</div>
									</div>
									<table class="table table-bordered table-hover">
										<thead>
											<tr>
											<th scope="col" class="text-sm text-center" style="width: 80px;">NO</th>
											<th scope="col" class="text-sm" style="min-width: 200px;">NAMA</th>
											<th scope="col" class="text-sm" style="width: 100px;">JENIS</th>
											<th scope="col" class="text-sm" style="width: 110px;">JUMLAH</th>
											<th scope="col" class="text-sm" style="width: 200px;">SIGNA</th>
											<th scope="col" class="text-sm text-center" style="min-width: 100px;">HARGA</th>
											<th scope="col" class="text-sm text-center" style="width: 110px;">ACTION</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(row, index) in resep" :key="row.id">
												<td style="width: 80px;" class="text-center">{{ index + 1 }}</td>
												<td style="min-width: 200px;">{{ row.nama }}</td>
												<td style="width: 100px;">
													{{ row.jenis == 'alat_kesehatan' ? 'Alkes' : row.jenis == 'percetakan' ? 'Percetakan' : 'Obat' }}
												</td>
												<td style="width: 110px;">
													<input class="form-control" type="number" step="any" v-model="row.jumlah" name="jumlah" @change="generateTotal(resep)">
												</td>
												<td style="width: 200px;">
													<el-select v-model="row.signa" filterable placeholder="Pilih Signa">
														<el-option value="" selected>- Signa -</el-option>
														<el-option v-for="(row, index) in signa" :key="index" :value="row">{{row}}</el-option>
													</el-select>
												</td>
												<td style="min-width: 100px;" class="text-right">{{ format_nominal(row.total)  }}</td>
												<td style="width: 110px;" class="text-center"><a class="btn btn-block btn-outline-danger btn-xs" @click="hapusDataResep(index)">Hapus</a></td>
											</tr>
											<tr>
												<td colspan="2" class="text-left font-weight-bold" style="font-size: large;">Total:</td>
												<td colspan="4" class="text-right font-weight-bold" style="font-size: large;">{{format_nominal(total_harga)}}</td>
												<td></td>
											</tr>
										</tbody>
										</table>
										<p class="text-center text-sm" v-if="memuat_data">Memuat Data...</p>
									<button type="submit" class="btn btn-primary d-flex align-items-center">
										<div>
											Submit
										</div>	
										<div>
											<svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style=" background: none; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
											<g>
												<path d="M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843" fill="none" stroke="#ffffff" stroke-width="12"></path>
												<path d="M49 3L49 27L61 15L49 3" fill="#ffffff"></path>
												<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
											</g>
											</svg>
										</div>
										</button>
									</div>
									<!-- /.card-body -->					               
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import ContentHeader from '@/components/ContentHeader2'
	import LoadingScreen from '@/components/LoadingScreen'
	import Multiselect from '@suadelabs/vue3-multiselect'

	import { useRoute } from 'vue-router'
	import { ref, onMounted, computed } from 'vue'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	import router from '@/router'
	import store from '@/store'
	import format_nominal from '@/format_nominal'

	// Signa
	const signa = ref([
		"3 x 0,5 p.c",
		"3 x 1 p.c",
		"3 x 1,5 p.c",
		"3 x 2 p.c",
		"3 x 0,5 a.c",
		"3 x 1 a.c",
		"3 x 1,5 a.c",
		"3 x 2 a.c",
		"2 x 0,5 p.c",
		"2 x 1 p.c",
		"2 x 1,5 p.c",
		"2 x 2 p.c",
		"2 x 0,5 a.c",
		"2 x 1 a.c",
		"2 x 1,5 a.c",
		"2 x 2 a.c",
		"1 x 0,5 p.c",
		"1 x 1 p.c",
		"1 x 1,5 p.c",
		"1 x 2 p.c",
		"1 x 0,5 a.c",
		"1 x 1 a.c",
		"1 x 1,5 a.c",
		"1 x 2 a.c",
	])
	const newSigna 	  = ref(null)
	const addNewSigna = () => {
		if(newSigna) {
			signa.value.push(newSigna.value)
			newSigna.value = null
		}
	}
	// End Signa

	const user = computed(() => store.getters['auth/user'])
	const cabang = computed(() => store.getters['auth/cabang'])
	const loading = ref(false)
	const loading_screen = ref(true)
	const memuat_data = ref(true)

	const route = useRoute()
	const kunjungan_id = route.params.id
	const rekam_medis = ref([])
	const rekam_medis_update = ref({
		'cabang_id' : user.value.cabang_id,
		'kunjungan_id' : route.params.id,
		'rekam_medis_id' : '',
		'resep' : []
	})

	const rekam_medis_result = ref([])
	const resepData = ref([])
	const obat_select_value = ref('')

	const getRekamMedis = async () => {
		let response = await axios.get(`api/rekam_medis/getWhereId/${kunjungan_id}/${cabang.value.tipe}`)
		rekam_medis.value = response.data

		for (var i = 0; i < response.data.kode_icdx.length; i++) {
			rekam_medis_result.value.push({
				'kode_icdx': response.data.kode_icdx[i],
				'nama_icdx': response.data.nama_icdx[i]
			})
		}
	}

	const data_resep_dan_obat = ref([])

	const getResepData = async () => {
		let response = await axios.get(`api/resep_obat/get/${user.value.cabang_id}`)

		if (response.data == 'kosong') {
			resepData.value = []
		} else {
			resepData.value = response.data
		}
		getObat()
	}

	const obat = ref([])

	const getObat = async () => {
		let response = await axios.get(`api/stok_produk/getStokProduk/${user.value.cabang_id}`)

		if (response.data == 'kosong') {
			obat.value = []
		} else {
			obat.value = response.data
		}
		gabungkanDataResepDanObat()
	}

	const gabungkanDataResepDanObat = () => {
		for (var i = 0; i < resepData.value.length; i++) {
			data_resep_dan_obat.value.push({
				"id" : resepData.value[i].id,
				"kode" : resepData.value[i].kode,
				'jenis' : 'resep',
				"nama" : resepData.value[i].nama,
				"harga" : resepData.value[i].harga,
				"stok" : resepData.value[i].stok,
				"jumlah" : 1,
				"fee_dokter" : resepData.value[i].fee_dokter,
			}) 
		}
		for (var j = 0; j < obat.value.length; j++) {
			data_resep_dan_obat.value.push({
				"id" : obat.value[j].id,
				"kode" : obat.value[j].produk.kode,
				'jenis' : obat.value[j].produk.jenis,
				"nama" : obat.value[j].produk.nama,
				"harga" : cabang.value.tipe == 'kecantikan' ? obat.value[j].harga_jual ? obat.value[j].harga_jual : obat.value[j].produk.harga_jual : obat.value[j].harga_jual ? obat.value[j].harga_jual : obat.value[j].produk.harga_jual_pasien,
				"stok" : obat.value[j].stok_akhir,
				"jumlah" : 1,
				"fee_dokter" : obat.value[j].produk.fee_dokter,
			}) 
		}

		loading_screen.value = false
		memuat_data.value = false
	}

	// Generate Total
	const total_harga = ref(0)
	const generateTotal = (dataResep) => {
		total_harga.value = 0
		if (dataResep.length > 0) {
			for (let i = 0; i < dataResep.length; i++) {
				dataResep[i].total = dataResep[i].harga * dataResep[i].jumlah
				total_harga.value = total_harga.value + dataResep[i].total
			}
		} else {
			total_harga.value = 0
		}
	}
	// End Generate Total


	// ---------Get data tindakan & resep obat---------
	const resep = ref([])
	const getResep = async () => {
		let response = await axios.get(`api/rekam_medis/getWhereId/${kunjungan_id}/${cabang.value.tipe}`)

		getRekamMedisDetailResepDanObat(response.data.id)
	}

	const getRekamMedisDetailResepDanObat = async (id) => {
		let { data } = await axios.get(`api/rekam_medis/getDetailResep/${id}/${cabang.value.tipe}`)
		
		if (data == 'kosong') {
			resep.value = []
		} else {
			resep.value = data
			resep.value.map((val) => {
				if(!signa.value.includes(val.signa)){
					signa.value.push(val.signa)
				}
			})
		}
		generateTotal(resep.value)
	}

	// ---------End Get data tindakan & resep obat---------

	const tambahResep = () => {
		console.log(data_resep_dan_obat.value)
		let data = data_resep_dan_obat.value.filter(row => {
			if (row.id == obat_select_value.value.id && row.kode == obat_select_value.value.kode) {
				return row
			}
		})
		
		if (data[0]) {
			data = data[0]
			resep.value.push({
				'id': obat_select_value,
				'resep_atau_obat_id' : data.id,
				'jenis' : data.jenis,
				'kode': data.kode,
				'nama': data.nama,
				'harga': data.harga,
				'stok': data.stok,
				'jumlah': 1,
				'fee_dokter' : data.fee_dokter,
				'hapus' : true
			})
		}

		generateTotal(resep.value)
	}

	const hapusDataResep = (index) => {
		resep.value.splice(index, 1)

		generateTotal(resep.value)
	}


	const prosesKonfirmasi = () => {
		if (loading.value == false) {
			loading.value = true

			rekam_medis_update.cabang_id = user.value.cabang_id
			rekam_medis_update.kunjungan_id = kunjungan_id
			rekam_medis_update.rekam_medis_id = rekam_medis.value.id 
			rekam_medis_update.resep = resep.value 
			
			axios.put(`api/rekam_medis/konfirmasi/${cabang.value.tipe}`, rekam_medis_update)
			.then((response) => {
				Swal.fire({
					title: 'Berhasil!',
					text: 'Berhasil Mengonfirmasi Resep',
					icon: 'success',
					showConfirmButton: false,
					timer: 1500
				})	
				loading.value = false
				console.log(response.data)
				router.push('/farmasi')
			})
			.catch((error) => {
				Swal.fire({
					title: 'Gagal!',
					text: 'Gagal mengonfirmasi obat',
					icon: 'error',
					showConfirmButton: false,
					timer: 1500
				})	
				loading.value = false
				console.log(error)
			})
		}
	}

	onMounted(() => {
		getRekamMedis()
		getResep()
		getResepData()
	})
</script>